.LeftSidebarFooter {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    width: -webkit-fill-available;
}

.LeftSidebarHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    flex-direction: column;
}
